<template>
  <div draggable="false">
    <span class="custom-label">Kolon Tipi</span>
    <div class="d-block bg-light rounded p-2 mb-2">
      <v-select
        label="name"
        v-bind:options="typeOptions"
        v-bind:reduce="(r) => r.id"
        v-bind:value="computedData.type"
        v-on:input="onChangeType"
      ></v-select>
    </div>

    <span class="custom-label">Arkaplan Görseli</span>
    <div class="d-block bg-light rounded p-2 mb-2">
      <!-- Background Uploader -->
      <input
        type="file"
        v-bind:ref="`${componentId}-fileInput`"
        v-bind:id="componentId + '-file'"
        class="form-control d-none"
        accept="image/*"
        v-on:change="onChangeBackground"
      />
      <!-- ./Background Uploader -->

      <div class="btn-group">
        <!-- Change Background Image -->
        <button
          type="button"
          class="btn btn-sm btn-primary"
          v-tooltip.top="'Arkaplanı Değiştir'"
          v-on:click="changeBackground"
        >
          <i
            v-bind:class="[
              imageUploading ? 'fas fa-spinner fa-spin' : 'fas fa-image fa-sm',
            ]"
          ></i>
          <span class="ml-2">Ekle/Değiştir</span>
        </button>
        <!-- ./Change Background Image -->

        <!-- Remove Background Image -->
        <button
          v-if="computedData.style.backgroundImage !== 'none'"
          type="button"
          class="btn btn-sm btn-danger"
          v-tooltip.top="'Arkaplanı Kaldır'"
          v-on:click="removeBackground"
        >
          <i class="fas fa-ban fa-sm"></i> <span>Kaldır</span>
        </button>
        <!-- ./Remove Background Image -->
      </div>

      <!-- Background Image -->
      <template v-if="computedData.style.backgroundImage !== 'none'">
        <!-- Image -->
        <img
          v-bind:src="computedData.style.backgroundImage"
          class="img-fluid mt-3 mb-2"
        />
        <!-- ./Image -->

        <div class="mb-2">
          <!-- Background Repeat -->
          <label class="custom-label">background-repeat</label>
          <v-select
            label="name"
            v-bind:clearable="false"
            v-bind:reduce="(p) => p.id"
            v-bind:options="backgroundRepeatSettings"
            v-model="computedData.style.backgroundRepeat"
          />
          <!-- ./Background Repeat -->
        </div>

        <div class="mb-0">
          <!-- Background Size -->
          <label class="custom-label">background-size</label>
          <WeInput
            class="mb-0"
            prepend="%"
            v-bind:filter-number="true"
            v-model="computedData.style.backgroundSize"
          />
          <!-- ./Background Size -->
        </div>
      </template>
      <!-- ./Background Image -->
    </div>

    <!-- Background Color -->
    <span class="custom-label">Arkaplan Rengi</span>
    <div class="d-block bg-light rounded p-2 mb-2">
      <WeInput v-model="computedData.style.backgroundColor" class="mb-0">
        <div slot="append" class="input-group-text">
          <verte
            picker="square"
            model="hex"
            v-bind:show-history="false"
            menu-position="right"
            v-model="computedData.style.backgroundColor"
          ></verte>
        </div>
      </WeInput>
    </div>
    <!-- ./Background Color -->

    <!-- Width -->
    <span class="custom-label">Genişlik</span>
    <div class="d-block bg-light rounded p-2 mb-2">
      <WeInput
        v-model="computedData.style.width"
        v-bind:filter-number="true"
        class="mb-0"
        append="%"
      />
    </div>
    <!-- ./Width -->

    <!-- Gap -->
    <template v-if="computedData.type !== 'editor'">
      <span class="custom-label">İçerik Ara Boşlukları</span>
      <div class="d-flex bg-light rounded p-2 mb-2">
        <WeInput
          label="Dikey"
          v-model="computedData.style.rowGap"
          v-bind:filter-number="true"
          class="mb-0 mr-2"
          append="px"
        />
        <WeInput
          label="Yatay"
          v-model="computedData.style.columnGap"
          v-bind:filter-number="true"
          class="mb-0"
          append="px"
        />
      </div>
    </template>
    <!-- ./Gap -->

    <!-- Padding -->
    <span class="custom-label">Kenar Boşlukları</span>
    <div class="d-block bg-light rounded p-2 mb-2">
      <WeInput
        v-model="computedData.style.padding"
        v-bind:filter-number="true"
        class="mb-0"
        append="px"
      />
    </div>
    <!-- ./Padding -->

    <!-- Border Radius -->
    <span class="custom-label">Kenar Çizgisi</span>
    <div class="d-block bg-light rounded p-2 mb-2">
      <WeInput
        v-model="computedData.style.borderWidth"
        v-bind:filter-number="true"
        class="mb-0"
        prepend="Kalınlık"
        append="px"
      />
      <WeInput
        v-model="computedData.style.borderColor"
        prepend="Renk"
        class="my-2"
      >
        <div slot="append" class="input-group-text">
          <verte
            picker="square"
            model="hex"
            v-bind:show-history="false"
            menu-position="right"
            v-model="computedData.style.borderColor"
          ></verte>
        </div>
      </WeInput>
      <v-select
        label="name"
        v-bind:clearable="false"
        v-bind:reduce="(p) => p.id"
        v-bind:options="borderStyleSettings"
        v-model="computedData.style.borderStyle"
      />
    </div>
    <!-- ./Border Radius -->

    <!-- Border Radius -->
    <span class="custom-label">Oval Kenar</span>
    <div class="d-block bg-light rounded p-2">
      <WeInput
        v-model="computedData.style.borderTopLeftRadius"
        v-bind:filter-number="true"
        class="mb-2"
        prepend="Sol Üst"
        append="px"
      />
      <WeInput
        v-model="computedData.style.borderTopRightRadius"
        v-bind:filter-number="true"
        class="mb-2"
        prepend="Sağ Üst"
        append="px"
      />
      <WeInput
        v-model="computedData.style.borderBottomLeftRadius"
        v-bind:filter-number="true"
        class="mb-2"
        prepend="Sol Alt"
        append="px"
      />
      <WeInput
        v-model="computedData.style.borderBottomRightRadius"
        v-bind:filter-number="true"
        class="mb-0"
        prepend="Sağ Alt"
        append="px"
      />
    </div>
    <!-- ./Border Radius -->
  </div>
</template>
<script>
import Verte from "verte";
import "verte/dist/verte.css";
import { mapActions } from "vuex";

export default {
  name: "ColumnSettings",
  components: {
    Verte,
  },
  props: {
    block: {
      default: null,
    },
  },
  model: {
    prop: "block",
  },
  data() {
    return {
      imageUploading: false,
      typeOptions: [
        { id: "editor", name: "Editor" },
        { id: "products", name: "Ürün" },
        { id: "brands", name: "Marka" },
      ],
      backgroundRepeatSettings: [
        {
          id: "no-repeat",
          name: "no-repeat",
        },
        {
          id: "repeat",
          name: "repeat",
        },
        {
          id: "repeat-x",
          name: "repeat-x",
        },
        {
          id: "repeat-y",
          name: "repeat-y",
        },
      ],
      borderStyleSettings: [
        {
          id: "solid",
          name: "solid",
        },
        {
          id: "dashed",
          name: "dashed",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      uploadImage: "imageUploader/upload",
    }),
    removeBackground() {
      this.computedData.style.backgroundImage = "none";
    },
    changeBackground() {
      this.fileUploader.click();
    },
    onChangeBackground() {
      const file = this.fileUploader.files[0];
      const reader = new FileReader();
      this.imageUploading = true;

      this.uploadImage({
        file: file,
        name: "column-background",
        onSuccess: (result) => {
          if (result?.data?.url) {
            reader.readAsDataURL(file);
            reader.onload = (e) => {
              this.computedData.style.backgroundImage = result.data.url;
            };
          }
        },
        onFinish: () => {
          this.imageUploading = false;
        },
      });
    },
    onChangeType(e) {
      this.computedData.type = e;
    },
  },
  computed: {
    computedData: {
      get() {
        return this.block;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    fileUploader() {
      return this.$refs[this.componentId + "-fileInput"];
    },
    componentId() {
      return "we-row-block-" + helper.getRandomInteger(1, 999999);
    },
  },
};
</script>